<template>
    <div class="page_wrapper reg_form">
        <div class="page_labels mb-7">
            <h1 class="mb-2">
                {{ $t('auth.create_password') }}
            </h1>
            <p>
                {{ $t('auth.create_strong_password') }}
            </p>
        </div>
        <a-form-model
            ref="authForm"
            :model="form"
            layout="vertical"
            autocomplete="off"
            :rules="rules">
            <a-form-model-item
                ref="password"
                class="mb-2"
                prop="password">
                <a-input-password
                    v-model="form.password"
                    autocomplete="off"
                    :placeholder="$t('auth.create_strong_password_placeholder')"
                    size="large"/>
            </a-form-model-item>
            <a-form-model-item
                ref="password2"
                class="mb-2"
                prop="password2">
                <a-input-password
                    v-model="form.password2"
                    autocomplete="off"
                    :placeholder="$t('auth.repeat_password_placeholder')"
                    size="large"/>
            </a-form-model-item>
            <a-button
                type="primary"
                :loading="loading"
                block
                class="form_buttom"
                size="large"
                @click="formSubmit()">
                {{ $t('auth.save_password') }}
            </a-button>
        </a-form-model>
    </div>
</template>

<script>
export default {
    props: {
        setForgotStep: {
            type: Function,
            default: () => {}
        },
        globalForm: {
            type: Object,
            default: () => null
        },
        setGlobalForm: {
            type: Function,
            default: () => {}
        }
    },
    data() {
        let validatePass = (rule, value, callback) => {
            if (value === '') {
                callback(new Error(this.$t('auth.field_required')));
            } else if (value !== this.form.password) {
                callback(new Error(this.$t('auth.passwords_do_not_match')));
            } else {
                callback();
            }
        };
        return {
            loading: false,
            form: {
                password: '',
                password2: ''
            },
            rules: {
                password: [
                    { required: true, message: this.$t('auth.field_required'), trigger: 'change' },
                    { min: 8, message: this.$t('auth.required_sym', { sym: 8 }), trigger: 'change' }
                ],
                password2: [
                    { validator: validatePass, trigger: 'change' },
                    { required: true, message: this.$t('auth.field_required'), trigger: 'change' },
                    { min: 8, message: this.$t('auth.required_sym', { sym: 8 }), trigger: 'change' }
                ]
            }
        }
    },
    methods: {
        formSubmit() {
            this.$refs.authForm.validate(async valid => {
                if (valid) {
                    try {
                        this.loading = true
                        const formData = {
                            ...this.globalForm,
                            ...this.form
                        }
                        const { data } = await this.$http.put(`/users/reset/password/${this.globalForm.uuid}/`, formData)
                        if(data) {
                            this.$message.success(this.$t('auth.password_changed_successfully'));
                            this.$router.push({name: 'login'})
                        }
                    } catch(error) {
                        if(error.message) {
                            this.$message.error(error.message, 4)
                        }
                        console.log(error)
                    } finally {
                        this.loading = false
                    }
                } else {
                    return false
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.pass_check{
    margin: initial;
    max-width: 100%;
    &::v-deep{
        .Password__strength-meter{
            margin: 10px auto 10px;
        }
    }
}
.password_text{
    font-size: 13px;
}
</style>